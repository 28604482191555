<template>
  <base-section
    v-if="_idLotto && lotto"
    id="customer-reviews"
    class="grey lighten-5"
    space="64"
  >
    <base-section-heading
      icon="fa-spray-can"
      space="0"
      :subtitle="lotto ? $t('title') : ''"
      :title="lotto ? lotto : $t('subtitle')"
    >
      <base-btn
        :outlined="view === 'history'"
        @click="view = 'card'"
      >
        {{ $i18n.t('d1') }}
      </base-btn>
      &nbsp;
      <base-btn
        :outlined="view === 'card'"
        @click="view = 'history'"
      >
        {{ $i18n.t('d2') }}
      </base-btn>

      <span v-if="view==='card'">
        <br><br>
        <v-icon
          :disabled="model === 0"
          @click="model--"
        >
          mdi-chevron-left
        </v-icon>
        {{ $i18n.t('d3') }}
        <v-icon
          :disabled="model === tasks.length - 1"
          @click="model++"
        >
          mdi-chevron-right
        </v-icon>
      </span>
      <!--  *** CARD ***-->
      <v-carousel
        v-if="view==='card'"
        v-model="model"
        class="mt-4"
        height="500"
        light
        :show-arrows="false"
        hide-delimiters
        ide-delimiters
      >
        <v-carousel-item
          v-for="(task, i) in tasks "
          :key="i"
        >
          <v-container>
            <v-responsive
              class="mx-auto shrink text-center"
              max-width="90%"
              width="700"
            >
              <base-body
                tag="em"
                class="mb-1 d-block"
              />
              <base-img
                v-if="!task.notExecuted"
                :src="require(`@/assets/imgs/sanitization/executed.jpg`)"
              />
              <base-img
                v-if="task.notExecuted"
                :src="require(`@/assets/imgs/sanitization/notExecuted.jpg`)"
              />
              <base-title
                :title="task.title"
                space="0"
              />
              <base-subtitle
                :title="task.subtitle"
              />
              <base-subtitle
                :title="task.text"
              />
            </v-responsive>
          </v-container>
        </v-carousel-item>
      </v-carousel>

      <!-- *** TABLE ***-->
      <v-simple-table
        v-if="view === 'history'"
        class="mt-12"
      >
        <thead>
          <tr>
            <th class="text-body-1 font-weight-bold text-center">
              {{ $t('date') }}
            </th>
            <th class="text-body-1 font-weight-bold text-center" />
            <th class="text-body-1 font-weight-bold text-left">
              {{ $i18n.t('d4') }}
            </th>
          </tr>
        </thead>

        <tbody class="text-body-1">
          <tr
            v-for="(task, i) in tasksTable"
            :key="i"
          >
            <td style="text-align: center; width: 25%;">
              {{ task.title }}
            </td>
            <td style="text-align: right; width: 5%;">
              <v-icon
                v-if="task.notExecuted"
                color="error"
              >
                mdi-flag
              </v-icon>
              <v-icon
                v-else
                color="success"
              >
                mdi-flag
              </v-icon>
            </td>
            <td style="text-align: left; width: 70%;">
              {{ task.subtitleShort }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-section-heading>
  </base-section>
</template>

<script>
  const Moment = require('moment')

  export default {
    name: 'SectionCustomerReviews',

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({
      view: 'card',
      model: 0,
      _idLotto: null,
      lotto: null,
      tasks: [],
      tasksTable: [],
    }),
    // **************
    // ** COMPUTED **
    // **************
    computed: {
      locale () {
        return this.$root.$i18n.locale
      },
    },
    watch: {
      locale () {
        Moment.locale(this.locale)
        this.fill(this.results)
      },
    },

    created () {
      const url = 'https://api.sanybeach.com/api/'
      const lotto = this.$route.query.lotto || this.$route.query.l
      if (lotto) {
        this._idLotto = lotto
        sessionStorage._idLotto = this._idLotto
      } else {
        this._idLotto = sessionStorage._idLotto
      }

      if (this._idLotto) {
        fetch(url + 'lotti/' + this._idLotto)
          .then(res => res.json())
          .then((result) => {
            if (result.lotto) {
              this.lotto = result.lotto._stabilimento.nome + ' ' + result.lotto.descrizione
            } else {
              this.lotto = null
            }
          }).catch((err) => {
            this.lotto = null
            console.log(err)
          })
        fetch(url + 'sanificazioni/' + this._idLotto)
          .then(res => res.json())
          .then((results) => {
            if (results[0]) {
              this.results = results
              this.fill(results)
            }
          }).catch((err) => {
            console.log(err)
          })
      }
    },
    // *************
    // ** METHODS **
    // *************
    methods: {
      fill (interventi) {
        this.tasks = []
        let idx = 0
        let dateTask = ''
        let msLastTask = Date.now()

        while (idx < interventi.length) {
          dateTask = Moment(interventi[idx].timestamp).format('YYYYMMDD')
          if (dateTask >= Moment(msLastTask).add(-1, 'days').format('YYYYMMDD')) {
            // se la data del task letto è ugualia o maggiore dell'ultimo task letto -1 giorno la inserisco (oppure oggi -1 se prima volta)
            if (Moment(interventi[idx].timestamp).format('DD-MM-YY hh:mm') !== Moment(msLastTask).format('DD-MM-YY hh:mm')) {
              if (dateTask === Moment(new Date()).format('YYYYMMDD')) {
                const sanitized = {
                  notExecuted: false,
                  title: this.$i18n.t('sanitisation.title') + Moment(interventi[idx].timestamp).format('hh:mm'),
                  subtitle: this.$i18n.t('sanitisation.by') + interventi[idx]._dipendente._fornitore.ragioneSociale + ' ' + interventi[idx]._dipendente.nome + ' ' + interventi[idx]._dipendente.cognome,
                  subtitleShort: interventi[idx]._dipendente._fornitore.ragioneSociale + ' ' + interventi[idx]._dipendente.nome + ' ' + interventi[idx]._dipendente.cognome,
                  text: this.$i18n.t('sanitisation.subtitle'),
                }
                this.tasks.push(sanitized)
              } else {
                const sanitized = {
                  notExecuted: false,
                  title: Moment(interventi[idx].timestamp).format('DD-MM-YY hh:mm'),
                  subtitle: this.$i18n.t('sanitisation.sanitised') + interventi[idx]._dipendente._fornitore.ragioneSociale + ' ' + interventi[idx]._dipendente.nome + ' ' + interventi[idx]._dipendente.cognome,
                  subtitleShort: interventi[idx]._dipendente._fornitore.ragioneSociale + ' ' + interventi[idx]._dipendente.nome + ' ' + interventi[idx]._dipendente.cognome,
                  text: '',
                }
                this.tasks.push(sanitized)
              }
            }
            msLastTask = Moment(interventi[idx].timestamp)
            idx = idx + 1
          } else {
            // altrimenti inserisco un intervento non eseguito per quella data (ieri se prima volta)
            msLastTask = Moment(msLastTask).add(-1, 'days')
            const sanitized = {
              notExecuted: true.constructor,
              title: Moment(msLastTask).format('DD-MM-YY'),
              subtitle: this.$i18n.t('sanitisation.not-sanitised'),
              subtitleShort: this.$i18n.t('sanitisation.not-sanitised'),
            }
            this.tasks.push(sanitized)
          }
        }
        this.tasksTable = this.tasks.slice()
        this.tasks = this.tasks.reverse()
        this.model = this.tasks.length - 1
      },
    },
  }
</script>

<style lang="sass">
  #customer-reviews
    .v-carousel__controls
      background: transparent

    .base-body
      line-height: 2
</style>
<i18n>
{
	"en": {
		"d1": "Detail",
		"d2": "History",
		"d3": "Sanitisations",
		"d4": "Intervention",
		"date": "Date",
		"sanitisation": {
			"by": "by ",
			"not-sanitised": "Sanitization not carried out due to unsuitable weather conditions ",
			"sanitised": "Sanitisation carried out by ",
			"subtitle": "Now everythting is sanitised - you can enjoy the beach without worries!",
			"title": "SANITISATION CARRIED OUT TODAY AT "
		},
		"subtitle": "No information available",
		"title": "This bathhouse has taken part to the Sanybeach project"
	},
	"it": {
		"d1": "Dettaglio",
		"d2": "Cronologia",
		"d3": "Sanificazioni",
		"d4": "Intervento",
		"date": "Data",
		"sanitisation": {
			"by": "da ",
			"not-sanitised": "Sanificazione non eseguita a causa delle condizioni metereologiche non idonee",
			"sanitised": "Sanificazione eseguita da ",
			"subtitle": "Ora è tutto sanificato – puoi goderti la spiaggia senza preoccupazioni!",
			"title": "SANIFICAZIONE ESEGUITA OGGI ALLE "
		},
		"subtitle": "Nessuna informazione disponibile",
		"title": "Il tuo stabilimento partecipa al progetto SanyBeach"
	}
}
</i18n>
